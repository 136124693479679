import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

import { useGlobals } from '@/composables/useGlobals';
import { useProfileStore } from '@/stores/profile/profileStore';
import type {
  ICreateReportContactDetails,
  ICreateReportParams,
  ICreateReportScheduleDetails,
  ICreateReportStructureDetails,
} from '@/types';
import { Route, ScheduleType } from '@/types';

export const useCreateReportStore = defineStore('createReportStore', () => {
  const { t } = useGlobals();
  const profileStore = useProfileStore();
  const route = useRoute();

  const DEFAULT_INTRO_MESSAGE = computed(() =>
    t('v2.reports.default.intro.message'),
  );

  const DEFAULT_TITLE = computed(() => t('v2.reports.default.title'));

  const isTemplatesEnabled = ref(false);

  const defaultCompanyName = computed(
    () => profileStore.contact?.companyName || '',
  );
  const defaultEmail = computed(() => profileStore.contact?.email || '');
  const defaultPhoneNumber = computed(() => {
    if (!profileStore.contact?.phone || !profileStore.contact?.phoneCc) {
      return '';
    }

    return `${profileStore.contact.phoneCc}${profileStore.contact.phone}`;
  });

  const defaultWebsiteAddress = computed(() => '');

  const isEditScheduleFlow = computed(
    () => route.name === Route.DeveloperTools.EDIT_REPORT_SCHEDULE,
  );

  const details = ref<ICreateReportParams>({
    domain: '',
    title: DEFAULT_TITLE.value,
    introMessage: DEFAULT_INTRO_MESSAGE.value,
    contactDetails: {
      companyName: defaultCompanyName.value,
      email: defaultEmail.value,
      phone: defaultPhoneNumber.value,
      websiteAddress: defaultWebsiteAddress.value,
    },
    structure: {},
    deliverToEmail: '',
    schedule: {
      scheduleType: ScheduleType.NONE,
      scheduleValue: undefined,
      scheduleHour: undefined,
    },
  });

  const setDomain = (targetDomain: string) => {
    details.value.domain = targetDomain;
  };

  const set = <K extends keyof ICreateReportParams>(
    field: K,
    value: ICreateReportParams[K],
  ) => {
    details.value = {
      ...details.value,
      [field]: value,
    };
  };

  const setContactDetails = <K extends keyof ICreateReportContactDetails>(
    field: K,
    value: ICreateReportContactDetails[K],
  ) => {
    details.value.contactDetails = {
      ...details.value.contactDetails,
      [field]: value,
    };
  };

  const setStructureDetails = <K extends keyof ICreateReportStructureDetails>(
    field: K,
    value: ICreateReportStructureDetails[K],
  ) => {
    details.value.structure = {
      ...details.value.structure,
      [field]: value,
    };
  };

  const setScheduleDetails = <K extends keyof ICreateReportScheduleDetails>(
    field: K,
    value: ICreateReportScheduleDetails[K],
  ) => {
    if (field === 'scheduleType') {
      details.value.schedule = {
        scheduleType: value as ScheduleType,
        scheduleValue: undefined,
        scheduleHour: undefined,
      };

      return;
    }

    details.value.schedule = {
      ...details.value.schedule,
      [field]: value,
    };
  };

  const setDeliverToEmail = (receiverEmail: string) => {
    details.value.deliverToEmail = receiverEmail;
  };

  const resetContactDetails = () => {
    details.value.contactDetails = {
      companyName: '',
      email: '',
      phone: '',
      websiteAddress: '',
    };
  };

  const getIsDetailsScheduleTypeSelected = (type: ScheduleType) =>
    details.value.schedule.scheduleType === type;

  const $reset = () => {
    details.value = {
      domain: '',
      title: DEFAULT_TITLE.value,
      introMessage: DEFAULT_INTRO_MESSAGE.value,
      contactDetails: {
        companyName: defaultCompanyName.value,
        email: defaultEmail.value,
        phone: defaultPhoneNumber.value,
        websiteAddress: defaultWebsiteAddress.value,
      },
      structure: {},
      deliverToEmail: '',
      schedule: {
        scheduleType: ScheduleType.NONE,
        scheduleValue: undefined,
        scheduleHour: undefined,
      },
    };
  };

  return {
    details,
    isEditScheduleFlow,
    isTemplatesEnabled,
    setDomain,
    set,
    setContactDetails,
    setStructureDetails,
    setScheduleDetails,
    setDeliverToEmail,
    resetContactDetails,
    getIsDetailsScheduleTypeSelected,
    $reset,
  };
});
