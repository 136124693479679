import type { Header } from '@/types';

export enum ReportState {
  NOT_STARTED = 'not_started',
  PREPARING = 'preparing',
  BACKUPS_IN_PROGRESS = 'backups_in_progress',
  PAGE_SPEED_IN_PROGRESS = 'page_speed_in_progress',
  SECURITY_IN_PROGRESS = 'security_in_progress',
  READY = 'ready',
  FAILED = 'failed',
}

export enum ScheduleType {
  NONE = 'none',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
}

export enum ScheduleTypeReq {
  DAY_OF_WEEK = 'day_of_week',
  DAY_OF_MONTH = 'day_of_month',
}

export type ReportHeaders = {
  [Header.CLIENT_ID]: string;
  [Header.ORDER_ID]: string;
  [Header.USERNAME]: string;
  [Header.DOMAIN]: string;
};

export interface ICreateReportMainDetails {
  title: string;
  introMessage: string;
}
export type MainDetailsKeys = keyof ICreateReportMainDetails;

export interface ICreateReportContactDetails {
  companyName: string;
  email: string;
  phone: string;
  websiteAddress: string;
}

export type ContactDetailsKeys = keyof ICreateReportContactDetails;

export interface ICreateReportStructureDetails {
  overview?: boolean;
  performance?: boolean;
  security?: boolean;
  backup?: boolean;
}

export interface ICreateReportScheduleDetails {
  scheduleType: ScheduleType;
  scheduleValue?: string;
  scheduleHour?: string;
}

export interface ICreateReportRequestParams extends ICreateReportMainDetails {
  contactDetails: ICreateReportContactDetails;
  structure?: ICreateReportStructureDetails;
  directory?: string;
  deliverToEmail?: string;
}

export interface ICreateReportParams extends ICreateReportMainDetails {
  domain: string;
  contactDetails: ICreateReportContactDetails;
  structure?: ICreateReportStructureDetails;
  directory?: string;
  deliverToEmail?: string;
  schedule: ICreateReportScheduleDetails;
}

export interface IScheduleReportParams
  extends ICreateReportParams,
    IScheduleParams {}

export interface IScheduleParams {
  scheduleType: ScheduleTypeReq;
  scheduleValue: string | null;
  scheduleHour: string;
}

export interface IReportStateResponse {
  state: ReportState;
  documentKey: string;
}

export interface IGetReportStateRequestData {
  params: { directory: string };
  headers: ReportHeaders;
}

export interface IGenerateReportRequestData {
  params: ICreateReportRequestParams;
  headers: ReportHeaders;
}

export interface IReportsListItem {
  id: string;
  createdAt: string;
  documentKey: string;
  state: ReportState;
  title: string;
  domain: string;
}

export interface IReportsScheduledListItem {
  id: string;
  createdAt: string;
  website: string;
  receiver: string;
  nextSchedule: string;
}

export interface IReportsScheduledListRes {
  data: IReportsScheduledListItem[];
  total: number;
  totalPages: number;
  perPage: number;
  page: number;
}

export interface IReportsListRes {
  data: IReportsListItem[];
  total: number;
  totalPages: number;
  perPage: number;
  page: number;
}
